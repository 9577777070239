.logo {
  width: 90px;
}

.Brand {
  /* text-align: left; */
  max-width: 280px;
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .Brand {
    max-width: 250px;
  }
}

@media only screen and (min-width: 250px) and (max-width: 360px) {
  .Brand {
    max-width: 180px;
  }
}

@media only screen and (max-width: 250px) {
  .Brand {
    max-width: 120px;
  }
}
