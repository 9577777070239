.footerContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
}

.footer {
  background-color: #f8f9fa;
  padding: 20px;
  margin-top: auto;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.email {
  margin: 0;
}

.copyright {
  margin: 0;
}
