/* Custom CSS for LayersControl */
.leaflet-control-layers {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-family: 'Arial', sans-serif;
}

.leaflet-control-layers-toggle {
  background-image: url('https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/layers.png');
  width: 36px;
  height: 36px;
}

.leaflet-control-layers-expanded {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
}

.leaflet-control-layers-base label {
  font-weight: bold;
  color: #333;
  padding: 5px;
  display: block;
}

.leaflet-control-layers-base input {
  margin-right: 5px;
}

.leaflet-popup-content h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #333;
}

.leaflet-popup-content p {
  margin: 5px 0 0;
  padding: 0;
  color: #555;
}

/* Styles for the nested radio buttons */
.leaflet-control-layers-list input[type='radio'] {
  display: none; /* Hide the default radio buttons */
}

/* Custom toggle button styles */
.leaflet-control-layers-list label {
  display: inline-block;
  padding: 4px 8px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.6s ease;
  /* color: #555353; */

  font-weight: normal;
  margin: 2px;
}

/* Styles for the selected toggle button */
.leaflet-control-layers-list input[type='radio']:checked + label {
  background-color: #007bff;
  color: #fff;
}

/* Make the selected option visible */
.leaflet-control-layers-list input[type='radio']:checked ~ span {
  /* background-color: #007bff; */
  /* color: #fff; */
  color: #555353;
  font-weight: bold;
}

/* Optional: Styles for nested labels */
.leaflet-control-layers-list ul label {
  margin-left: 20px;
}

/* Make the selected option visible */
.leaflet-control-layers-list input[type='radio']:checked ~ ul {
  display: block;
}

.leaflet-control-layers-list ul {
  display: none;
  margin-left: 20px;
  padding-left: 10px;
  border-left: 2px solid #ccc;
}

.fullscreen-control {
  position: absolute;
  top: 10px;
  /* right: 10px; */
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  padding: 5px;
  cursor: pointer;
}

/* Override react-bootstrap modal styles for mobile */
@media screen and (max-width: 768px) {
  .modal-dialog {
    margin: 0 !important;
    max-width: 100% !important;
    height: 100vh;
  }

  .modal-content {
    border-radius: 0 !important;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }

  .modal-body {
    max-height: calc(100vh - 60px); /* Subtract header height */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .modal-header {
    position: sticky;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
}

/* Override modal styles for all screen sizes */
.modal-content {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: none !important;
  color: white !important;
}

.modal-body {
  color: white !important;
}

.modal-header {
  border: none !important;
  padding: 1rem !important;
}

.modal-header .btn-close {
  filter: invert(1) !important;
  opacity: 0.8 !important;
  padding: 0.7rem !important;
  border: solid 2px black !important;
}

.modal-header .btn-close:hover {
  opacity: 1 !important;
}

.modal-title {
  color: white !important;
  font-size: 1.5rem !important;
  padding-right: 2rem !important;
}

.modal-nav-buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  padding: 0 20px;
}

.modal-nav-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.modal-nav-button:hover {
  opacity: 1;
  text-decoration: underline;
}

.modal-nav-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
  text-decoration: none;
}

.point-info-header {
  background-color: rgba(104, 141, 147, 0.3);
  color: #fff;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.point-info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  opacity: 0.9;
}

.point-info-item:last-child {
  margin-bottom: 0;
}

.point-info-item svg {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .point-info-header {
    border-radius: 0;
  }
}
