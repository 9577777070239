.CardTitle {
  text-align: center;
  padding: 8px 2px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.Title {
  width: 70%;
  text-align: center;
  /* background-color: rgba(255, 255, 255, 0.7); */
  /* background-color: rgba(104, 141, 147, 0.7); */
  /* color: aliceblue; */
  /* border-radius: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
}
