/* Hourly Forecast List Styles */
.hourlyForecastContainer {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.hourlyForecastTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  color: #333;
  font-size: 1.2rem;
}

.hourlyForecastList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.hourlyForecastItem {
  background-color: white;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.hourlyTime {
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.measurementRow {
  display: grid;
  gap: 12px;
}

.measurement {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.measurementLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

.showMoreContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.showMoreButton {
  min-width: 120px;
}

/* Header row styles */
.headerRow {
  display: none;
}

/* Mobile styles */
@media (max-width: 767px) {
  .hourlyTime {
    padding-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 10px;
  }

  .measurementRow {
    display: grid;
    grid-template-columns: 1fr;
  }

  .measurement {
    padding: 8px 0;
  }

  /* Hide icons in the measurement values for mobile */
  .measurement span svg:not(.wind-direction-arrow) {
    display: none;
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .headerRow {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #e9ecef;
    border-radius: 6px;
    margin-bottom: 8px;
    font-weight: bold;
    gap: 12px;
  }

  .headerRow .hourlyTime {
    width: 160px;
  }

  .headerRow .measurement {
    width: 120px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
  }

  .headerRow .measurement span {
    margin-left: 4px;
  }

  .headerRow .measurement:nth-child(5) {
    width: 100px;
  }

  .headerRow .measurement:nth-child(6) {
    width: 140px;
  }

  .headerRow .measurement:nth-child(2) {
    width: 130px;
  }

  .measurement:nth-child(1) {
    width: 130px;
  }

  .hourlyForecastItem {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    transition: background-color 0.2s ease;
  }

  .hourlyForecastItem:hover {
    background-color: #f0f8ff;
  }

  .hourlyTime {
    width: 180px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .measurementRow {
    display: flex;
    flex: 1;
    gap: 12px;
  }

  .measurement {
    width: 120px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .measurement span {
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
  }

  .measurement svg {
    margin-right: 4px;
  }

  .measurementLabel {
    display: none;
  }

  .measurement:nth-child(5) {
    width: 80px;
  }

  .measurement:nth-child(6) {
    width: 140px;
  }
}
