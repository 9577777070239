.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.kayak-loader {
  max-width: 120px;
  width: 100%;
  height: auto;
  animation: kayak-rotate 2s infinite linear;
  transform-origin: center center;
}

@keyframes kayak-rotate {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(360deg);
  }
}
