.quick-access-section {
  margin: 15px 0;
}

.quick-access-section h5 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

.quick-access-section h5 .location-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.quick-access-buttons {
  margin: 10px 0 !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 8px !important;
  justify-content: center !important;
  padding: 5px !important;
  /* background-color: #f8f9fa; */
  border-radius: 8px !important;
}

.quick-access-button {
  border-radius: 20px !important;
  padding: 6px 12px !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
}

.loading-container {
  text-align: center;
  margin: 10px 0;
}
