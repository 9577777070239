.EditTrips {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.Label {
  margin-top: 14px;
  font-size: 1.2rem;
  font-weight: 500;
}

.Error {
  background-color: red;
  font-size: 1.2rem;
  font-weight: 600;
  color: #dbd9d9;
  width: 100%;
  padding: 0.5rem;
  height: 2.5rem;
  text-align: center;
}
