.App {
  text-align: center;
}

h2 {
  text-align: center;
  /* margin: 3.5rem 0 3.5rem; */
  position: relative;
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #2d3748;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
}

h2::before,
h2::after {
  content: '';
  display: block;
  height: 3px;
  background: linear-gradient(to right, transparent, #4a90e2, transparent);
  margin: 0 2rem;
  flex: 1;
  max-width: 200px;
  border-radius: 2px;
}

h2::before {
  background: linear-gradient(to right, transparent, #4a90e2);
}

h2::after {
  background: linear-gradient(to left, transparent, #4a90e2);
}

/* Mobile styles */
@media (max-width: 768px) {
  h2 {
    font-size: 1.8rem;
    margin: 2.5rem 0 2.5rem;
    letter-spacing: 2px;
    padding: 0.5rem 0.5rem;
  }

  h2::before,
  h2::after {
    margin: 0 1rem;
    max-width: 100px;
  }
}

/* Small mobile styles */
@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
    margin: 2rem 0 2rem;
    letter-spacing: 1px;
  }

  h2::before,
  h2::after {
    margin: 0 0.5rem;
    max-width: 60px;
  }
}

/* p {
  padding-left: 1rem !important;
} */

.color-nav {
  background-color: #688d93;
}
.navbar-light .navbar-nav .nav-link {
  /* color: #312b25; */
  color: rgb(205, 236, 241);
}

.nav-link.active {
  color: #fff !important;
}

.nav-link:hover {
  color: #fff !important;

  /* color: rgb(205, 236, 241) !important; */
}

a:link {
  color: #8b6361;
}

/* visited link */
a:visited {
  color: #8b6361;
}

/* mouse over link */
a:hover {
  color: gray;
}

/* selected link */
a:active {
  color: gray;
}
/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

.app-container {
  text-align: left;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Media query for larger screens */
@media (min-width: 1000px) {
  .app-container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}
