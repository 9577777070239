.PicButton {
  color: white !important;
  margin-top: 10px !important;
  border-radius: 4px !important;
}

/* media max-width 1016px */
@media (max-width: 1016px) {
  .PicButton {
    border-radius: 0 !important;
  }
}
