.PointHeader {
  padding: 1rem;
  background-color: #688d93;
  /* color: rgba(0, 0, 0, 0.9); */
  color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1000px) {
  .PointHeader {
    border-radius: 0;
  }
}
