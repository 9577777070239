.Forecast {
  border-width: 1px;
  border-color: black;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.centerText {
  text-align: center;
}

.locationTitle {
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.forecastButton {
  margin: 5px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.coordinatesInfo {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.spinnerContainer {
  text-align: center;
  margin-top: 2rem;
}

.errorContainer {
  text-align: center;
  margin-top: 15px;
}

.noDataContainer {
  text-align: center;
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.surfSpotsButton {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 1000;
  width: 60px !important ;
  height: 60px !important;
  border-radius: 50% !important;
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  padding: 0;
  cursor: pointer;
}
