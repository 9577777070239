.surf-spots-modal .modal-dialog {
  margin-bottom: 0;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  transform: translate(0, 100%);
  transition: transform 0.3s ease-out;
}

.surf-spots-modal.show .modal-dialog {
  transform: translate(0, 0);
}

.surf-spots-modal .modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
}

.surf-spots-modal .modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.surf-spots-modal .modal-body {
  padding: 0;
}

.surf-spots-map-container {
  width: 100%;
  height: 500px;
}

/* Handle for dragging the modal */
.surf-spots-modal .modal-header::before {
  content: '';
  display: block;
  width: 40px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  margin: 0 auto 10px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Button container */
.surf-spots-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px !important;
  left: auto !important;
  z-index: 1000;
  width: 60px;
  height: 60px;
  pointer-events: none;
  margin-left: auto;
  margin-right: 0;
}

/* Button to open the surf spots map */
.surf-spots-button {
  position: relative;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 1000;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  padding: 0;
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  overflow: hidden;
  pointer-events: auto;
  /* Reset any inherited styles */
  margin: 0;
  flex: none;
  text-align: center;
  line-height: normal;
  box-sizing: border-box;
  float: right;
}

.surf-spots-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.surf-spots-button svg {
  font-size: 24px;
  width: 24px;
  height: 24px;
}

/* Ensure the button doesn't get affected by parent container styles */
.surf-spots-button:before,
.surf-spots-button:after {
  display: none;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .surf-spots-modal .modal-dialog {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .surf-spots-modal .modal-dialog {
    max-width: 80%;
  }
}

/* Surf spot popup styling */
.surf-spot-popup h3 {
  margin-top: 0;
  margin-bottom: 8px;
  color: #007bff;
  font-size: 18px;
  font-weight: 600;
}

.surf-spot-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.surf-spot-header svg {
  flex-shrink: 0;
}

.surf-spot-popup p {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.4;
}

.surf-spot-popup p strong {
  font-weight: 600;
  color: #555;
}

/* Leaflet popup customization */
.leaflet-popup-content-wrapper {
  border-radius: 8px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
}

.leaflet-popup-content {
  margin: 12px 16px;
  min-width: 200px;
}

.leaflet-popup-tip {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
}

/* Custom surf marker icon styling */
.surf-marker {
  background: none;
  border: none;
}

.surf-marker-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border: 2px solid #0066cc;
  transition: transform 0.2s ease;
}

.surf-marker-icon:hover {
  transform: scale(1.1);
}

/* Clickable title in popup */
.clickable-title {
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  position: relative;
}

.clickable-title:hover {
  color: #0056b3;
}

.clickable-title::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #0066cc;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.clickable-title:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Forecast button */
.popup-footer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.forecast-button {
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.forecast-button:hover {
  background-color: #0056b3;
}

.forecast-button:active {
  transform: translateY(1px);
}
