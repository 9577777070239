.About p {
  font-size: 1.2rem;
}

.AboutImg {
  width: 100%;
  margin-bottom: 1rem;
}

/* media max-width 1016px */
@media (max-width: 1016px) {
  .About p {
    font-size: 1.2rem;
    margin-left: 8px;
  }
  .AboutImg {
    width: 100%;
    margin-bottom: 1rem;
  }
  .AboutContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
